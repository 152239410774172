import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'manual-journals',
        path: '/manual-journals',
        redirect: {
            name: 'manual-journals.list',
        },
        children: [
            {
                name: 'manual-journals.list',
                path: '',
                component: () => import('./pages/manual-journals-list.vue'),
            },
            {
                name: 'manual-journals.create',
                path: 'create',
                component: () => import('./pages/manual-journals-create.vue'),
                permission: 'create_manual_journals',
            },
            {
                name: 'manual-journals.edit',
                path: 'edit/:id',
                component: () => import('./pages/manual-journals-edit.vue'),
                permission: 'update_manual_journals',
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_manual_journals',
    },
}
