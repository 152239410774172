const navigation = [
    {
        id: 'sas-manual-journals',
        path: 'manual-journals',
        label: 'manualJournals.sidebar.label',
        parent: 'sas-bookeeping',
        position: 70,
        permission: 'view_manual_journals',
    },
]

export default navigation
