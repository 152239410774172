<template>
    <div class="mb-6 grid max-w-[320px] gap-2.5">
        <form-date-picker
            :label="$t('manualJournals.information.dateLabel')"
            single
            v-model="date"
            :error-message="errorDate"
        />

        <form-text-input
            v-model="referenceNumber"
            :label="$t('manualJournals.information.refNumberLabel')"
            :error-message="errorReferenceNumber"
        />

        <form-text-input
            v-model="journalId"
            :label="$t('manualJournals.information.journalIdLabel')"
            :error-message="errorJournalId"
            disabled
        />

        <form-textarea
            :label="$t('manualJournals.information.noteLabel')"
            :placeholder="$t('manualJournals.information.notePlaceholder')"
            rows="6"
            v-model="note"
            :error-message="errorNote"
        />
    </div>
</template>
<script setup>
const { value: date, errorMessage: errorDate } = useField('date')
const { value: referenceNumber, errorMessage: errorReferenceNumber } =
    useField('reference_number')
const { value: journalId, errorMessage: errorJournalId } =
    useField('journal_id')
const { value: note, errorMessage: errorNote } = useField('note')
</script>
