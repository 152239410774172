<template>
    <form-field-array
        :fields="fields"
        :label-add="$t('manualJournals.lineItems.add')"
        :min="2"
        @on-remove-item="onRemoveItem"
        @on-add-item="onAddItem"
        class="mb-[30px]"
    >
        <template #default="{ item: { value, key } }">
            <form-control-group class="flex-1">
                <span class="text-sm text-danger-500" v-if="errorJournalLines">
                    {{ errorJournalLines }}
                </span>

                <form-control-group-row>
                    <form-field-array-error
                        :name="`manual_journal_lines[${key}].chart_of_account_id`"
                        #="{ errorMessage }"
                    >
                        <form-detail-chart-of-account
                            v-model="value.chart_of_account_id"
                            :error-message="errorMessage"
                            :include-modal="false"
                            :excluded-types="[
                                'accounts-receivable-ar',
                                'accounts-receivable-ap',
                                'credit-card',
                                'cash-and-cash-equivalents',
                            ]"
                            hide-message
                            :input-display="
                                getChartOfAccountName(value.chart_of_account)
                            "
                            :label="
                                key === 0
                                    ? $t(
                                          'manualJournals.lineItems.accountLabel'
                                      )
                                    : ''
                            "
                            :placeholder="$t('general.selectAccount')"
                            @open-modal="onOpenAddModal(value)"
                        />
                    </form-field-array-error>

                    <div class="flex-1 shrink-0 basis-[47%]">
                        <form-field-array-error
                            :name="`manual_journal_lines[${key}].description`"
                            #="{ errorMessage }"
                        >
                            <form-text-input
                                v-model="value.description"
                                hide-message
                                :label="
                                    key === 0
                                        ? $t(
                                              'manualJournals.lineItems.descriptionLabel'
                                          )
                                        : ''
                                "
                                :placeholder="
                                    $t(
                                        'manualJournals.lineItems.descriptionPlaceholder'
                                    )
                                "
                                :error-message="errorMessage"
                            />
                        </form-field-array-error>
                    </div>

                    <form-field-array-error
                        :name="`manual_journal_lines[${key}].debit`"
                        #="{ errorMessage }"
                    >
                        <form-number-input
                            v-model="value.debit"
                            @update:model-value="
                                value.debit > 0 ? (value.credit = 0) : ''
                            "
                            hide-message
                            :label="
                                key === 0
                                    ? $t('manualJournals.lineItems.debitLabel')
                                    : ''
                            "
                            :placeholder="
                                $t('manualJournals.lineItems.debitPlaceholder')
                            "
                            :error-message="errorMessage"
                            input-class="pr-16"
                            :precision="2"
                        />
                    </form-field-array-error>

                    <form-field-array-error
                        :name="`manual_journal_lines[${key}].credit`"
                        #="{ errorMessage }"
                    >
                        <form-number-input
                            v-model="value.credit"
                            @update:model-value="
                                value.credit > 0 ? (value.debit = 0) : ''
                            "
                            hide-message
                            :label="
                                key === 0
                                    ? $t('manualJournals.lineItems.creditLabel')
                                    : ''
                            "
                            :placeholder="
                                $t('manualJournals.lineItems.creditPlaceholder')
                            "
                            :error-message="errorMessage"
                            input-class="pr-16"
                            :precision="2"
                        />
                    </form-field-array-error>
                </form-control-group-row>
            </form-control-group>
        </template>

        <template #add-button="{ isReachMax, onAddItem }">
            <div class="mr-16 flex justify-between gap-x-4">
                <base-button
                    class="self-start"
                    outline
                    variant="default"
                    icon="line-icons:general:plus"
                    :disabled="isReachMax"
                    @click="onAddItem"
                >
                    {{ $t('manualJournals.lineItems.add') }}
                </base-button>

                <div
                    v-if="defaultCurrency"
                    class="grid w-[50%] grid-cols-3 gap-2.5 rounded-lg bg-gray-100 p-6"
                >
                    <p class="text-md text-gray-900">
                        {{ $t('manualJournals.lineItems.subtotal') }}
                    </p>
                    <p class="flex justify-end text-md text-gray-900">
                        {{
                            $filters.currency(
                                totalDebit,
                                defaultCurrency?.iso,
                                2
                            )
                        }}
                    </p>
                    <p class="flex justify-end text-md text-gray-900">
                        {{
                            $filters.currency(
                                totalCredit,
                                defaultCurrency?.iso,
                                2
                            )
                        }}
                    </p>

                    <p class="text-md font-semibold text-gray-900">
                        {{ $t('manualJournals.lineItems.total') }}
                    </p>
                    <p
                        class="flex justify-end text-md font-semibold text-gray-900"
                    >
                        {{
                            $filters.currency(
                                totalDebit,
                                defaultCurrency?.iso,
                                2
                            )
                        }}
                    </p>
                    <p
                        class="flex justify-end text-md font-semibold text-gray-900"
                    >
                        {{
                            $filters.currency(
                                totalCredit,
                                defaultCurrency?.iso,
                                2
                            )
                        }}
                    </p>

                    <p class="text-md text-danger-600">
                        {{ $t('manualJournals.lineItems.difference') }}
                    </p>
                    <p class="flex justify-end text-md text-danger-600">
                        {{
                            difference >= 0
                                ? $filters.currency(
                                      difference,
                                      defaultCurrency?.iso,
                                      2
                                  )
                                : ''
                        }}
                    </p>

                    <p class="flex justify-end text-md text-danger-600">
                        {{
                            difference < 0
                                ? $filters.currency(
                                      Math.abs(difference),
                                      defaultCurrency?.iso,
                                      2
                                  )
                                : ''
                        }}
                    </p>
                </div>
            </div>
        </template>
    </form-field-array>

    <bookeeping-accounts-add-modal
        :show="showAddModal"
        @modal-close="onCloseAddModal"
        @on-finished="onFinished"
    />
</template>
<script setup>
import { getChartOfAccountName } from '@tenant/utils/helper'
import { AMOUNT_TYPE } from '@tenant/utils/constants'

const { errorMessage: errorJournalLines } = useField('manual_journal_lines')
const { remove, push, fields } = useFieldArray('manual_journal_lines')

const onRemoveItem = (idx) => {
    if (fields.value.length > 1) {
        remove(idx)
    }
}

const onAddItem = () => {
    push({
        chart_of_account_id: null,
        description: null,
        debit: null,
        credit: null,
    })
}

const { defaultCurrency } = useCurrencies()

const { totalNet: totalDebit } = useNetGross(fields, AMOUNT_TYPE.NET, 'debit')
const { totalNet: totalCredit } = useNetGross(fields, AMOUNT_TYPE.NET, 'credit')

const difference = computed(() => {
    return totalCredit.value - totalDebit.value
})

const { value: differenceValue } = useField('difference')
watch(
    () => difference.value,
    (val) => {
        differenceValue.value = val
    },
    { immediate: true }
)

// COA MODAL
const { showAddModal, onCloseAddModal, onOpenAddModal, onFinished } =
    useCOAModal()
// END: COA MODAL
</script>
