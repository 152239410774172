<template>
    <data-grid
        :data-source="data"
        :columns="columns"
        :loading="loading"
        identify-field="id"
        @row-click="onRowClick"
        custom-classes="!rounded-none border-x-0"
        :border-table-header="true"
        :empty-text="$t('manualJournals.emptyText')"
        :empty-sub="$t('manualJournals.emptySubText')"
    >
        <template #outer-header>
            <div class="flex flex-1 flex-col">
                <div
                    class="flex w-full items-start justify-between gap-2 px-6 py-5"
                >
                    <div class="flex flex-col gap-1">
                        <div class="text-lg font-medium text-gray-900">
                            {{ $t('manualJournals.listing.title') }}
                        </div>
                    </div>
                    <div class="flex items-center gap-6">
                        <form-toggle
                            v-model="showReverse"
                            size="sm"
                            label="Show Reverse"
                        />
                        <base-button
                            v-if="$acl.can('create_manual_journals')"
                            size="sm"
                            variant="primary"
                            :label="$t('manualJournals.listing.addNew')"
                            @click="goToCreate"
                        />
                    </div>
                </div>
            </div>
        </template>

        <template #column-journal_id="{ item }">
            <div class="flex items-baseline gap-[5px]">
                <div
                    v-if="item.original_id && reversed"
                    class="h-[20px] w-[10px] -translate-y-1 border-b border-l border-gray-400"
                ></div>
                {{ item.journal_id }}
                <base-badge
                    v-if="item.original_id && reversed"
                    label="Reverse"
                    size="sm"
                    variant="gray"
                />
            </div>
        </template>

        <template #column-date="{ item }">
            {{ $filters.dateHumanFormat(item.date) }}
        </template>

        <template #column-status="{ item }">
            <partial-status :status="item.status" variant="small" />
        </template>

        <template #column-creator="{ item }">
            <div v-if="item.creator">
                {{ item.creator.full_name }}
            </div>
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
            />
        </template>
    </data-grid>
</template>
<script setup>
import { flatMap } from 'lodash-es'
import { useDebounceFn } from '@vueuse/core'

const { t } = useI18n()
const router = useRouter()
const showReverse = ref(false)
const reversed = ref(false)
const { entities, useApiSearch } = useApiFactory('manual-journals')

const { loading, execute } = useApiSearch({}, false, true)
const data = ref([])

watch(
    showReverse,
    useDebounceFn(async (value) => {
        await execute({
            ...(value
                ? {
                      filter: {
                          original_id: null,
                      },
                  }
                : {}),
            sort: {
                col: 'journal_id',
                by: 'desc',
            },
        })

        if (value) {
            data.value = flatMap(entities.value.data, (item) =>
                [item, item.reversed_journal].filter(Boolean)
            )
        } else {
            data.value = entities.value.data
        }

        reversed.value = value
    }, 200),
    { immediate: true }
)

useHead({
    title: t('manualJournals.listing.title'),
})

const columns = [
    {
        property: 'journal_id',
        label: t('manualJournals.listing.columns.journalId'),
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'date',
        label: t('manualJournals.listing.columns.date'),
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'reference_number',
        label: t('manualJournals.listing.columns.referenceNumber'),
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'status',
        label: t('manualJournals.listing.columns.status'),
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'amount',
        label: t('manualJournals.listing.columns.amount'),
        dataCellClass: '!text-gray-500',
    },
    {
        property: 'creator',
        label: t('manualJournals.listing.columns.creator'),
        headerCellClass: '!rounded-none',
        dataCellClass: '!text-gray-500',
    },
]

const onRowClick = (value, { id }) => {
    router.push({
        name: 'manual-journals.edit',
        params: { id },
    })
}

const goToCreate = () => {
    router.push({
        name: 'manual-journals.create',
    })
}
</script>
