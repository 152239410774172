<template>
    <base-sticky-heading
        :title="$t('manualJournals.edit.title')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-tippy
                    v-if="originalId"
                    placement="top"
                    :content="$t('manualJournals.edit.disableReverseMsg')"
                    container-classes="w-[240px] text-center"
                >
                    <base-button
                        :disabled="originalId"
                        :loading="loading"
                        @click="onCreateReverse"
                        variant="default"
                    >
                        {{ $t('manualJournals.edit.createReverse') }}
                    </base-button>
                </base-tippy>
                <base-button
                    v-else
                    :disabled="originalId"
                    :loading="loading"
                    @click="onCreateReverse"
                    variant="default"
                >
                    {{ $t('manualJournals.edit.createReverse') }}
                </base-button>

                <base-button
                    :disabled="difference !== 0"
                    :loading="loading"
                    @click="onSave"
                >
                    {{ $t('manualJournals.edit.saveAndClose') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <base-alert
                v-if="difference !== 0"
                variant="danger"
                :title="$t('manualJournals.differenceAlert.title')"
                :description="$t('manualJournals.differenceAlert.description')"
                class="mb-6"
            />

            <manual-journals-information />

            <manual-journals-line-items />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { JOURNAL_STATUS } from '@tenant/modules/tenant/manual-journals/utils/constants'
import { shortServerFormat } from '@tenant/utils/day'

const { t } = useI18n()

useHead({
    title: t('manualJournals.edit.title'),
})

const { setValues, handleSubmit, setErrors } = useForm()
const { value: difference } = useField('difference')
const originalId = useFieldValue('original_id')

const route = useRoute()
const router = useRouter()
const { errorNotify, successNotify } = useNotification()
const emitter = useEmitter()

const { useApiSave, useApiGet } = useApiFactory('manual-journals')
const { execute: executeGetJournal } = useApiGet()
const { execute, loading, code, parameters } = useApiSave()

onMounted(async () => {
    try {
        emitter.emit('loading', true)

        const {
            date,
            amount,
            reference_number,
            journal_id,
            note,
            manual_journal_lines,
            original_id,
        } = await executeGetJournal(route.params.id)
        setValues({
            date: shortServerFormat(date),
            amount,
            reference_number,
            journal_id,
            note,
            manual_journal_lines: manual_journal_lines.map((line) => ({
                ...line,
                chart_of_account_id: line.chart_of_account.id,
            })),
            original_id,
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('loading', false)
    }
})

const onSave = handleSubmit(async (values) => {
    try {
        emitter.emit('loading', true)

        await execute(
            {
                ...values,
                status: JOURNAL_STATUS.PUBLISHED,
            },
            route.params.id
        )

        successNotify({
            text: t('manualJournals.edit.saveSuccessMsg'),
        })
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('loading', false)
    }
})

const onCreateReverse = async () => {
    await router.push({
        name: 'manual-journals.create',
        query: {
            reverse_journal_id: route.params.id,
        },
    })
}
</script>
