<template>
    <base-sticky-heading
        :title="$t('manualJournals.create.title')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    :disabled="difference !== 0"
                    :loading="loading"
                    outline
                    variant="default"
                    @click="onSave(JOURNAL_STATUS.DRAFT)"
                >
                    {{ $t('manualJournals.create.saveAsDraft') }}
                </base-button>
                <base-button
                    :disabled="difference !== 0"
                    :loading="loading"
                    @click="onSave(JOURNAL_STATUS.PUBLISHED)"
                >
                    {{ $t('manualJournals.create.saveAndClose') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <base-alert
                v-if="difference !== 0"
                variant="danger"
                :title="$t('manualJournals.differenceAlert.title')"
                :description="$t('manualJournals.differenceAlert.description')"
                class="mb-6"
            />

            <manual-journals-information />

            <manual-journals-line-items />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import emitter from '@tenant/core/init/emitter'
import { JOURNAL_STATUS } from '@tenant/modules/tenant/manual-journals/utils/constants'
import { shortServerFormat } from '@tenant/utils/day'

const router = useRouter()
const { t } = useI18n()
const route = useRoute()
const { errorNotify } = useNotification()
const { useApiGet } = useApiFactory('manual-journals')
const { execute: executeGetJournal } = useApiGet()

const { setValues, handleSubmit, setErrors } = useForm()
const { value: difference } = useField('difference')
const { value: journalId } = useField('journal_id')
useField('original_id', undefined, {
    initialValue: route.query.reverse_journal_id,
})
const { execute: generateNumber } = useApi(
    '/api/sequence-settings/generate-number/manual_journal',
    'GET'
)

onMounted(async () => {
    journalId.value = await generateNumber()
})

onMounted(() => {
    setValues({
        manual_journal_lines: [
            {
                chart_of_account_id: null,
                description: null,
                debit: null,
                credit: null,
            },
            {
                chart_of_account_id: null,
                description: null,
                debit: null,
                credit: null,
            },
        ],
        difference: 0,
    })
})

const { successNotify } = useNotification()
const { useApiCreate } = useApiFactory('manual-journals')
const { execute, loading, code, parameters } = useApiCreate()

const onSave = handleSubmit(async (values, { evt: status = 'draft' }) => {
    try {
        emitter.emit('loading', true)

        await execute({
            ...values,
            status,
        })

        successNotify({
            title: t('manualJournals.create.saveSuccessMsg'),
        })

        await router.push({
            name: 'manual-journals.list',
        })
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('loading', false)
    }
})

onMounted(async () => {
    try {
        if (!route.query.reverse_journal_id) return

        emitter.emit('loading', true)

        const { date, amount, reference_number, note, manual_journal_lines } =
            await executeGetJournal(route.query.reverse_journal_id)
        setValues({
            date: shortServerFormat(date),
            amount,
            reference_number,
            note,
            manual_journal_lines: manual_journal_lines.map((line) => ({
                chart_of_account_id: line.chart_of_account.id,
                description: line.description,
                debit: line.credit,
                credit: line.debit,
            })),
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('loading', false)
    }
})
</script>
