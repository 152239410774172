export default {
    manualJournals: {
        emptyText: 'No manual journals found',
        emptySubText: 'Create your first journal',
        sidebar: {
            label: 'Manual Journals',
        },
        listing: {
            title: 'Manual Journals',
            addNew: 'New Journal',
            columns: {
                date: 'Date',
                journalId: 'Journal ID',
                referenceNumber: 'Reference Number',
                status: 'Status',
                amount: 'Amount',
                creator: 'Creator',
            },
        },
        information: {
            dateLabel: 'Date*',
            refNumberLabel: 'Reference Number',
            journalIdLabel: 'Journal ID*',
            noteLabel: 'Notes*',
            notePlaceholder: 'A note about the journal entry',
        },
        lineItems: {
            add: 'Add another line',
            accountLabel: 'Account',
            descriptionLabel: 'Description',
            descriptionPlaceholder: 'Enter a description',
            debitLabel: 'Debit',
            debitPlaceholder: '0.00',
            creditLabel: 'Credit',
            creditPlaceholder: '0.00',
            subtotal: 'Subtotal',
            total: 'Total',
            difference: 'Difference',
        },
        differenceAlert: {
            title: 'Debit & Credit always needs to be equal.',
            description:
                'Make sure that you enter the correct numbers as Debit & Credit always needs to be equal.',
        },
        create: {
            title: 'New Journal',
            saveAsDraft: 'Save as draft',
            saveAndClose: 'Save and close',
            saveSuccessMsg: 'Journal saved successfully',
        },
        edit: {
            title: 'Edit Journal',
            saveAndClose: 'Save and close',
            saveSuccessMsg: 'Journal saved successfully',
            createReverse: 'Create reverse entry',
            disableReverseMsg:
                'You can’t create a reverse entry for an already existing reverse entry',
        },
    },
}
